import React from 'react'
import ReactMarkdown from 'react-markdown'

interface Props {
  source: string
  className: string
}

function Markdown(props: Props) {
  return <ReactMarkdown source={props.source} className={props.className} />
}

export { Markdown }
